<template>
  <k-basic-modal-layout>
    <template v-slot:header>
      <div class="panel-header">
        <div class="d-flex align-items-center pr-20">
          <span class="title-border bg-blue-cyan mr-20"></span>
          <h1 class="hasab-title">Kép méretre vágása</h1>
        </div>
      </div>
    </template>
    <template v-slot:body>
      <div class="px-25">
        <k-image-cropper
          :imgSrc="imgSrc"
          ref="imageCropper"
          @crop-image="SaveImage"
        >
        </k-image-cropper>
      </div>
    </template>
    <template v-slot:footer>
      <div class="footer justify-content-between">
        <k-button
          variant="dark"
          label="Mégsem"
          @click="Close()"
          icon="fas fa-times"
        ></k-button>
        <k-button
          variant="primary"
          label="Mehet"
          @click="Mehet()"
          icon="fas fa-crop"
        ></k-button>
      </div>
    </template>
  </k-basic-modal-layout>
</template>

<script>
import { mapGetters } from 'vuex';
import { eventBus } from '../../main';

export default {
  name: 'image-cropper-modal',
  data() {
    return {
      imgSrc: null,
    };
  },
  mounted() {
    this.imgSrc = this.modal.data.kepUrl;
  },
  created() {},
  methods: {
    Mehet() {
      this.$refs.imageCropper.CropImage();
    },
    Close() {
      this.$emit('close');
    },
    SaveImage(kep) {
      eventBus.$emit('update-image', {
        cropped: kep,
      });
      this.Close();
    },
  },
  computed: {
    ...mapGetters({}),
  },
  watch: {},
  props: {
    modal: {
      type: Object,
      default: function() {
        return {};
      },
    },
  },
};
</script>
